import { PageProps } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";

const HrApply = (props: PageProps) => {
  const { formatMessage: fm } = useIntl();
  return (
    <main>
      <SEO title={fm({ id: "menu-contact" })} />
      <Layout inverse>
        <div className="w-full pt-40 h-screen font-cool flex flex-col relative justify-start bg-white">
          <iframe
            style={{ border: "none" }}
            src={`https://hr-test.steppeholding.mn/hr${
              (typeof window !== "undefined" && window?.location?.search) || ""
            }`}
            className="w-full h-screen"
          />
        </div>
        <div className="m-h-screen"></div>
      </Layout>
    </main>
  );
};

export default HrApply;
